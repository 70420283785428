import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import "../../store.css";
import { handleError } from "../../../../../utils/toast";
import { useFetchApi } from "../../../../../utils/queries";
import { Pagination } from "@mui/material";
import NoProducts from "./noProducts";
import ProductCard from "./productCard";
import Loading from "./Loading";
import FilterSection from "./filterSection";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ScrollButton from "./scrollButton";

const itemsPerPage = 9;

export default function StoreProducts({ isLandingPage }) {
  const [page, setPage] = useState(1);
  const [animateDirection, setAnimateDirection] = useState("");
  const { pathname } = useLocation();
  const [query, setQuery] = useState( () =>
    `product?limit=${page}&offset=${itemsPerPage}`
  );

  const { data, isLoading } = useFetchApi(
    "/product",
    page,
    pathname === "/products/store" ? itemsPerPage : "",
    query
  );

  const products = data?.data?.product || [];
  const prices = data?.data?.price || [];
 

  const totalPage = !query.includes("category") || query.includes("price") && !query.includes("minPrice")
    ? Math.ceil(data?.data?.count / itemsPerPage) ?? 1
    : Math.ceil(products.length / itemsPerPage) ?? 1;

  const combinedRef = useRef(null); // Single ref for both content and scroll container

  const handleChange = (event, value) => {
    setAnimateDirection(value > page ? "slide-left" : "slide-right");
    setPage(value);
  };

  useEffect(() => {
    if (combinedRef.current) {
      combinedRef.current.classList.remove(
        "animate-slide-left",
        "animate-slide-right"
      );
      void combinedRef.current.offsetWidth; // Trigger reflow to restart animation
      combinedRef.current.classList.add(`animate-${animateDirection}`);
    }
  }, [page]);

  if (data?.error) handleError(data.error);

  return (
    <div className="w-full h-auto flex gap-8 relative">
      {pathname === "/products/store" && (
        <FilterSection
          setQuery={setQuery}
          page={page}
          itemsPerPage={itemsPerPage}
          prices={prices}
        />
      )}
      <ScrollButton ref={combinedRef} dir="left">
        <IoIosArrowBack />
      </ScrollButton>
      <section
        className={` ${
          pathname === "/products/store" ? "w-full lg:w-9/12" : "sm:w-11/12 w-full"
        } h-full !-mt-10 min-h-max mx-auto store__container`}
      >
        <div
          ref={combinedRef}
          className={`w-full mt-12 pb-12 overflow-x-auto scroll-smooth gap-2 ${
            pathname === "/products/store"
              ? `${
                  products.length === 0 ? "" : "grid"
                } grid-cols-2 md:grid-cols-3 gap-y-[20px] md:gap-y-[80px] gap-x-[10px] md:gap-x-[35px]`
              : "flex space-x-4 max-md:space-x-5 scrollable-container"
          }`}
        >
          {isLoading ? (
            <Loading />
          ) : products.length === 0 ? (
            <NoProducts />
          ) : (
            products.map((product, index) => (
              <ProductCard key={product.id} product={product} index={index} />
            ))
          )}
        </div>
        {pathname === "/products/store" && (
          <div className="flex justify-center">
            <Pagination
              className="custom-pagination"
              color="primary"
              shape="rounded"
              variant="text"
              count={totalPage}
              page={page}
              onChange={handleChange}
            />
          </div>
        )}
      </section>
      <ScrollButton ref={combinedRef} dir="right">
        <IoIosArrowForward />
      </ScrollButton>
    </div>
  );
}

StoreProducts.propTypes = {
  isLandingPage: PropTypes.bool,
};
