import React from "react";
import PropTypes from "prop-types";
import { useLikedProductHandler, toggleLike } from "./likedProductHandler";
import ProductPrice from "./productPrice";
import ProductName from "./productName";
import StatusBadge from "./statusBadge";
import { CiHeart } from "react-icons/ci";
import { FaHeart } from "react-icons/fa";
import ImageWithLoader from "../../../../../common/components/product/ImageKit";
import logo from "../../../../../assets/icons/axaoIcon.svg";
import { useNavigate } from "react-router-dom";

function ProductCard({ product }) {
  const navigate = useNavigate();
  const { isLiked, setIsLiked } = useLikedProductHandler(product);

  // Check if the current product is liked
  const likedProduct = isLiked.find((p) => p.product_id === product.id);
  const isProductLiked = likedProduct?.status || false;

  const ImageLoader = () => (
    <div className="w-full h-auto my-40 animate-pulse flex justify-center items-center">
      <img
        className="logo w-10/12 h-full -translate-y-1/2 opacity-70"
        src={logo}
        alt="AXOA - Logo"
      />
    </div>
  );

  return (
    <div
      onClick={() =>
        product.stock_quantity >= 1 && navigate(`/products/store/${product.id}`)
      }
      className={`w-full min-w-[312px] lg:min-h-[375px] max-md:min-w-[200px] max-sm:min-w-[180px] bg-gradient-to-t from-[#000] from-10% via-[#0B0B0B] to-[#101010] rounded-[20px] overflow-hidden relative p-[12px] last:mr-36 ${
        product.stock_quantity < 1
          ? "cursor-not-allowed"
          : "store-me cursor-pointer"
      } product__container overflow-hidden`}
    >
      {/* Status Badge */}
      {product.status === "new" && <StatusBadge label="New" />}
      {product.status === "hot" && <StatusBadge label="Hot🔥" />}
      {product.stock_quantity < 1 && <StatusBadge label="Sold Out" />}
      {product.status === "preorder" && <StatusBadge label="Pre-Order" />}

      {/* Like/Unlike Button */}
      <div
        className="absolute top-5 right-5 z-10 heart"
        onClick={(e) => e.stopPropagation()} // Prevent parent click
      >
        {isProductLiked ? (
          <FaHeart
            className="text-red-600 likedHeart"
            onClick={() => toggleLike(setIsLiked, product.id)}
          />
        ) : (
          <CiHeart
            className="text-base"
            onClick={() => toggleLike(setIsLiked, product.id)}
          />
        )}
      </div>

      {/* Product Image and Details */}
      <figure className="w-full">
        <ImageWithLoader
          Loader={ImageLoader}
          src={product.images[0]}
          alt={product.name}
          className="opacity-80 w-[292px] rounded-lg max-sm:h-[125px] xl:h-[252px] mb-36"
        />
        <figcaption className="px-2 bg-black rounded-3xl absolute bottom-0 w-full right-0 left-0">
          <ProductName name={product.name} />
          <ProductPrice
            price={product.price}
            discount={product.discount}
            discountedAmount={product.discounted_amount}
          />
        </figcaption>
      </figure>
    </div>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    stock_quantity: PropTypes.number,
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    discount: PropTypes.number,
    images: PropTypes.array.isRequired,
    status: PropTypes.string,
    discounted_amount: PropTypes.string,
  }).isRequired,
};

export default ProductCard;